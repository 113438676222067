.settings-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.card {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.card h3 {
  margin-bottom: 15px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.input-group input[type="radio"] {
  margin-right: 10px;
}

.input-group input,
.input-group select {
  width: 100%; 
  max-width: 100%; 
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; 
}

.input-group-notification {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  display: inline-flex;
}

.input-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.success-message {
  text-align: center;
  color: green;
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
}

.submit-btn {
  text-align: center;
}

.submit-btn button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit-btn button:hover {
  background-color: #0056b3;
}

.clear-all {
  text-align: center;
  margin-bottom: 10px;
}

.clear-all button {
  background-color: #ff4d4d;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.clear-all button:hover {
  background-color: #cc0000;
}

@media (max-width: 768px) {
  .settings-container {
    padding: 15px;
  }

  .input-group input,
  .input-group select,
  .input-group textarea {
    font-size: 14px;
  }

  .submit-btn button {
    padding: 8px 16px;
  }
}