.profile-card {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

.profile-card__image {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto 15px;
}

.profile-card__image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  display: block;
}

.profile-card__icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-card__info label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
  text-align: left;
}

.profile-card__input, 
.profile-card__textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.profile-card__textarea {
  resize: none;
}

.editable {
  background-color: #fff;
}

.read-only {
  background-color: #f0f0f0;
}

.profile-card__edit-btn,
.profile-card__save-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.profile-card__settings-link {
  margin-top: 20px;
}

.profile-card__settings-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
