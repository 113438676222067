/* Mentor Home Page Container */
.mentor-home-page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
    background-color: rgba(240, 248, 255, 0.8);
    min-height: 100vh;
    box-sizing: border-box;
}

/* General header styling */
.mentor-header {
    grid-column: 1 / -1;
    text-align: center;
    padding-bottom: 40px;
}

.mentor-header h1 {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 20px;
    font-weight: 700;
}

/* General tile styles */
.tile {
    background: linear-gradient(145deg, #ffffff, #f9f9f9);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: center; /* Make sure content is centered */
}

.tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.tile h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: inherit;
    font-weight: 600;
}

.tile p {
    font-size: 1rem;
    margin: 10px 0;
    color: inherit;
    line-height: 1.5;
}

/* Responsive styles for tablet screens */
@media (max-width: 1024px) {
    .mentor-home-page {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .mentor-header h1 {
        font-size: 2rem;
    }

    .tile h2 {
        font-size: 1.5rem;
    }
}

/* Responsive styles for mobile screens */
@media (max-width: 768px) {
    .mentor-header h1 {
        font-size: 1.8rem;
    }

    .tile h2 {
        font-size: 1.3rem;
    }

    .tile p {
        font-size: 0.9rem;
    }

    .mentor-home-page {
        padding: 10px;
    }
}

/* Custom colors for different tile types */
.blue-tile {
    background-color: #3498db;
    color: white;
}

.white-tile {
    background-color: #ffffff;
    color: #333;
}
