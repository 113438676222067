.groups-page {
  padding: 20px;
}

.groups-list {
  display: flex;
  flex-wrap: wrap;
}

.group-tile {
  background-color: #f5f5f5;
  padding: 20px;
  margin: 10px;
  width: 300px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s;
}

.text-center {
  text-align: center;
}

.group-tile:hover {
  background-color: #e0e0e0;
}

.group-tile h2 {
  margin-top: 0;
}

.group-tile p {
  color: #555;
}

.create-group-button {
  display: block;
  margin: 20px auto;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.group-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.group-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.group-modal-input,
.group-modal-textarea,
.group-modal-select {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.group-modal-button {
  background-color: transparent;
  color: #007bff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.group-modal-button:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .group-tile {
    width: 100%;
  }

  .group-modal-content {
    width: 90%;
  }
}
