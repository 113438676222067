.events {
  padding: 20px;
}

.event-listings {
  margin-top: -200px;
  padding: 200px;
}

.button-center {
  display: flex; /* Enable flexbox */
  justify-content: center; /* Center horizontally */
  margin-top: 10px; /* Space above the button */
}

.create-event-button {
  margin-bottom: 7px;
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px 15px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

.create-event-button:hover {
  background-color: #1dd771; /* Darken on hover */
}
/* Modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

/* Modal content */
.modal-content {
  background-color: white; /* White background for the modal */
  border-radius: 8px; /* Rounded corners */
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
  width: 400px; /* Fixed width for the modal */
  max-width: 90%; /* Responsive max width */
}

/* Form elements */
.modal-content h2 {
  margin-top: 0; /* Remove top margin */
}

/* Form elements */
.modal-content input {
  width: 95%; /* Full width */
  padding: 10px; /* Padding for inputs */
  margin: 10px 0; /* Margin between inputs */
  border: 1px solid #ccc; /* Border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
  font-size: 16px; /* Increase font size for better readability */
}
.event-center{
  text-align: center;
}
.event-center-event{
  margin-top: 5px;
  text-align: center;
  font-size: 40px;
}

/* Optional: Style for textarea if used */
.modal-content textarea {
  width: 95%; /* Full width */
  padding: 10px; /* Padding for textarea */
  margin: 10px 0; /* Margin between textarea and other elements */
  border: 1px solid #ccc; /* Border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Inner shadow for depth */
  font-size: 16px; /* Increase font size for better readability */
  resize: vertical; /* Allow vertical resizing */
}


/* Submit button */
.modal-content button[type="submit"] {
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  padding: 10px; /* Padding */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
}

.modal-content button[type="submit"]:hover {
  background-color: #0056b3; /* Darker on hover */
}

/* Close button */
.modal-content button {
  background-color: transparent; /* Transparent background */
  color: #007bff; /* Button color */
  border: none; /* No border */
  cursor: pointer; /* Pointer cursor */
  margin-top: 10px; /* Space above the button */
}

.modal-content button:hover {
  text-decoration: underline; /* Underline on hover */
}
