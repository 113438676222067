/* General styles for the page */
.build-resume-resume-page {
    padding: 20px;
    text-align: center;
    margin-top: 60px; /* Adjust margin to create space for fixed button */
}

/* Ensure this remains below the fixed button */
.build-resume-resumes-list {
    margin-top: 20px; /* Add margin to create space from the header */
    padding: 10px;
    text-align: center;
}
  
  /* Header styles */
  h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .build-resume-create-resume-button {
    position: fixed; /* Keep the button fixed in the viewport */
  top: 80px; /* Distance from the bottom of the page */
  left: 20px; /* Distance from the right side of the page */
  background-color: #6ae485; /* Button color */
  color: white; /* Text color */
  padding: 10px 20px; /* Padding around the text */
  font-size: 16px; /* Font size */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color change */
  z-index: 100; /* Ensure the button stays above other content */
  }
  
  .build-resume-create-resume-button:hover {
    background-color: #4AAE65; /* Darker shade on hover */
  }
  
  /* Form overlay styles */
  .-resume-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Popup form styles */
  .resume-form-popup {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    width: 90%;
    max-width: 500px;
    text-align: left;
    overflow: auto; /* Allows scrolling if content overflows */
  }
  
  /* Form title */
  .resume-form h2 {
    margin-bottom: 15px;
    font-size: 1.8em;
  }
  
  /* Input and textarea styles */
  label {
    display: block;
    margin: 10px 0 5px;
  }
  
 .resume-input,
  textarea {
    width: calc(100% - 22px); /* Subtract padding and border */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    box-sizing: border-box; /* Include padding and border in width calculation */
  }
  
  textarea {
    resize: vertical; /* Allow vertical resizing */
    height: 100px; /* Set a default height for textareas */
  }
  
  /* Button container styles */
  .button-container {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    margin-top: 15px;
  }
  
  /* Button styles */
  .resume-button {
    background-color: #5DBB7A; /* Primary button color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    min-width: 100px; /* Set a minimum width for buttons for consistency */
  }
  
  button:hover {
    background-color: #4AAE65; /* Darker shade on hover */
  }
  
  .prev-button {
    background-color: #FF7F50; /* Different color for back button */
  }
  
  .next-button {
    background-color: #007BFF; /* Different color for next button */
  }
  
  .submit-button {
    background-color: #28A745; /* Different color for submit button */
    margin-right: 0 auto;
  }
  
  /* Header for the popup form */
  .resume-form-popup-header {
    display: flex;
    justify-content: space-between; /* Keep title and close button aligned properly */
    align-items: center;
  }
  
  .resume-close-icon {
    cursor: pointer;
    margin-left: auto; /* Ensures the close icon is on the far right */
    color: #FF3D3D; /* Icon color */
    width: 24px;
    height: 24px;
  }
  
  .resume-close-icon svg {
    width: 100%;
    height: 100%;
  }
  /* Center the Create New Resume button */
.create-resume-button {
    background-color: #5DBB7A; /* Button color */
    color: white;
    padding: 10px 50px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    display: inline-block; /* Ensures proper alignment */
    margin: 20px auto; /* Centers the button */
}

/* Adjust the button container for the form */
.resume-button-container {
    display: flex;
    justify-content: space-between; /* Adjust to space between buttons */
    margin-top: 15px;
}


  /* Responsive adjustments */
  @media (max-width: 600px) {
    .resume-popup {
      width: 90%;
    }
  
    .resume-button {
      width: 100%; /* Make buttons full-width on smaller screens */
      margin: 5px 0;
    }
  }
/* Add this new class for the fixed button */
.fixed-button-container {
    position: fixed;
    top: 70px; /* Adjust this value based on the height of your header */
    right: 20px;
    z-index: 1000; /* Ensure it stays on top */
}

/* Adjust the container for the resume cards */
.resume-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive card layout */
    gap: 20px;
    padding: 20px;
    justify-content: center;
  }
  
  /* Styling for each resume card */
  .resume-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease;
  }
  
  .resume-card:hover {
    transform: translateY(-5px); /* Slight lift effect on hover */
  }
  
  .resume-card h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  
  .resume-card p {
    font-size: 0.9em;
    color: #666;
  }
  .resume-page {
  padding: 20px;
}

.create-resume-button {
  background-color: #5DBB7A; /* Button color */
  color: white; /* Text color */
  padding: 10px 15px; /* Padding around button text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  margin-bottom: 20px; /* Space below button */
  transition: background-color 0.3s; /* Smooth background color change */
}

.create-resume-button:hover {
  background-color: #4AAE65; /* Darker shade on hover */
}
.resume-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .download-button,
  .delete-button {
    background-color: #007BFF; /* Default background color */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .download-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  .delete-button {
    background-color: #FF4136; /* Red color for delete button */
  }
  
  .delete-button:hover {
    background-color: #c23329; /* Darker red on hover */
  }
  
  .delete-button svg {
    margin-right: 5px; /* Add space between icon and text */
  }
  
/* Form overlay styles */
.form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above other elements */
}

.resume-form-popup {
  background-color: white; /* Background color for popup */
  padding: 20px; /* Padding inside the popup */
  border-radius: 10px; /* Rounded corners */
  width: 400px; /* Width of the popup */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Shadow for depth */
}

h2 {
  margin: 0 0 10px; /* Space below the title */
}

/* Resume card styles */
.resume-card {
  background-color: #f9f9f9; /* Light background color */
  border: 1px solid #ccc; /* Border around card */
  border-radius: 10px; /* Rounded corners */
  padding: 15px; /* Padding inside the card */
  margin: 10px 0; /* Space between cards */
  text-align: left; /* Align text to the left */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
}

.resume-card h3 {
  margin: 0 0 10px; /* Space below the title */
  font-size: 1.5em; /* Font size for title */
}

.resume-card p {
  margin: 5px 0; /* Space above and below paragraph */
  font-size: 0.9em; /* Slightly smaller font for date */
}

.resume-card button {
  background-color: #5DBB7A; /* Button color */
  color: white; /* Text color */
  padding: 8px 12px; /* Padding around button text */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth background color change */
}

.resume-card button:hover {
  background-color: #4AAE65; /* Darker shade on hover */
}

  .download-button {
    background-color: #007BFF; /* Download button color */
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .download-button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .resume-cards-container {
      grid-template-columns: 1fr; /* Single column for small screens */
    }
  }
  .delete-icon {
    cursor: pointer;
    color: rgb(79, 74, 74); /* Icon color */
    position: absolute; /* If you want to position it in the corner */
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    display: flex; /* Centering the icon */
    justify-content: center; /* Centering the icon */
    align-items: center; /* Centering the icon */
  }
  
  .delete-icon i {
    font-size: 20px; /* Adjust the size of the icon */
  }
  
  
  
  .resume-card {
    position: relative; /* So the delete icon is positioned relative to the card */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
  }
    

