.header-header {
  background-color: #eaf9f0;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-header__logo img {
  height: 50px;
  width: auto;
  object-fit: contain;
}


.header-header__nav ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.header-header__nav a {
  text-decoration: none;
  color: black;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.notification-badge {
  background-color: #ff5252; 
  color: white; 
  border-radius: 50%; 
  padding: 3px 6px; 
  margin-left: 5px; 
  font-size: 12px; 
  position: relative; 
  top: -2px; 
}

.header-header__nav a:hover {
  background-color: #d0f0e0;
}

.header-header__nav .active {
  color: #1515e7;
  font-weight: 600;
  background-color: #d0f0e0;
}


.header-dropdown {
  position: relative;
}

.header-dropdown > a::after {
  content: " ▼"; 
  font-size: 0.8em;
  margin-left: 5px;
}

.header-dropdown:hover > a::after {
  content: " ▲"; 
}

.header-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
  right: 0;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content a {
  display: block;
  color: black;
  padding: 10px 15px;
  text-decoration: none;
}

.header-dropdown-content a:hover {
  background-color: #d0f0e0;
}


.header-nested-dropdown {
  position: relative;
}

.header-nested-dropdown > a::after {
  content: " ▶"; 
  font-size: 0.8em;
  margin-left: 5px;
}

.header-nested-dropdown:hover > a::after {
  content: " ▼"; 
}

.header-nested-content {
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 160px;
  top: 0;
  left: 100%;
  margin-left: 1px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  border-radius: 5px;
}

.header-nested-dropdown:hover .header-nested-content {
  display: block;
}

@media (max-width: 768px) {
  .header-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-header__logo img {
    height: 40px;
  }

  .header-header__nav ul {
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 10px 0;
  }

  .header-header__nav ul li {
    width: 100%;
  }

  .header-header__nav a {
    display: block;
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }

  .header-dropdown-content, .header-nested-content {
    position: relative;
    box-shadow: none;
  }

  .header-dropdown-content {
    width: 100%;
    padding-left: 20px;
  }

  .header-nested-content {
    width: 100%;
    padding-left: 20px;
  }

  .header-dropdown-content a, .header-nested-content a {
    padding: 10px;
    width: 100%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header-header__logo img {
    height: 45px;
  }

  .header-header__nav ul {
    gap: 15px;
  }

  .header-header__nav a {
    padding: 5px 15px;
  }

  .header-dropdown-content, .header-nested-content {
    min-width: 140px;
  }
}
