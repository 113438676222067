/* Chat App */
.chat-app {
  display: flex;
  height: 100vh; /* Full viewport height */
  width: 100%;
}

/* Chat List Section */
.chat-list-section {
  flex: 0.2; /* 30% of the screen */
  background-color: #f4f4f9;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto; /* Allows scrolling if list is long */
}

/* Search Bar Styling */
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.search-bar input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  outline: none;
}

.search-bar button {
  padding: 8px 15px;
  background-color: #6ae485;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.search-bar button:hover {
  background-color: #23e166;
}

/* Chat List */
.chat-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat-list li {
  display: flex;
  align-items: center;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  text-transform: capitalize;
}

.chat-list li:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Profile Image */
.chat-list li img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
}

/* Chat Name */
.chat-list li .chat-name {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Subtext (last message or time) */
.chat-list li .chat-subtext {
  font-size: 14px;
  color: #777;
  margin-left: auto; /* Align to the right */
}

.list-text-center{
  text-align: center;
}





