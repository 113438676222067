.event-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #f0fdf4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.2s ease;
}

.event-card:hover {
  transform: translateY(-3px);
}

.event-card__info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.event-card__date-time {
  display: flex;
  flex-direction: column; /* Align date, time, and button vertically */
  align-items: flex-end; /* Align to the right */
  margin-left: 20px; /* Add spacing between info and date/time */
}

.event-card__title {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  color: #000;
}

.event-card__location {
  font-size: 16px;
  color: #5a00e5;
  text-decoration: none;
  margin-top: 5px;
}

.event-card__time {
  font-size: 14px;
  color: #555; /* Optional: Change the color to differentiate */
  margin: 5px 0; /* Add margin for spacing */
}

.event-card__link {
  margin-top: 5px;
}

.event-card__link a {
  color: #00f; /* Link color */
  
}
.event-card__link a:hover{
  text-decoration: underline; /* Underline links */
}

.event-interested-button-container {
  margin-top: 10px; /* Add margin for spacing between time and button */
}

.event-interested-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.event-card__date {
  font-size: 14px;
  color: #000;
  margin: -6px 0;
}
.event-delete-icon {
  cursor: pointer; /* Pointer cursor on hover */
  color: red; /* Color for delete icon */
  transition: color 0.2s; /* Smooth color transition */
  margin-top: 10px; /* Space above the icon */
}

.event-delete-icon:hover {
  color: darkred; /* Darker color on hover */
}
