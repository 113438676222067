.manage-events {
    padding: 20px;
}

.event-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.event-table th,
.event-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.event-table th {
    background-color: #f4f4f4;
}

.actions-cell {
    display: flex;
    gap: 10px;
}

.create-event-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px auto;
    display: block;
}

.event-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.event-modal-content {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    max-width: 90%; 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.event-modal-content input,
.event-modal-content textarea {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.event-modal-content button {
    background-color: #007bff; /* Create button color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.event-cancel-button {
    background-color: transparent; /* Cancel button color */
    color: #007bff; /* Change color to match */
}
