.home-home-page {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: rgba(168, 237, 215, 0.2);
  min-height: 100vh;
  box-sizing: border-box;
}

@media (min-width: 1024px) {
  .home-home-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .home-home-section {
    flex: 0 1 300px;
    margin: 10px;
  }
}

@media (max-width: 1024px) {
  .home-home-page {
    display: block;
    text-align: center;
  }

  .home-home-section {
    margin: 20px 0;
  }
}

.home-home-section {
  background: linear-gradient(145deg, #ffffff, #f9f9f9);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-home-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.home-h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
  border-bottom: 2px solid #ececec;
  padding-bottom: 10px;
  font-weight: 600;
}

.home-event-card p,
.home-job-card p,
.home-link-card p {
  margin: 10px 0;
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
}

.home-link-card a {
  color: #3498db;
  text-decoration: none;
  font-weight: 500;
}

.text-home-center {
  text-align: center;
}

.home-link-card a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.home-welcome-section {
  grid-column: 1 / -1;
  text-align: center;
  padding-bottom: 40px;
}

.home-welcome-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
}

.home-welcome-message {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.home-additional-content p,
.home-additional-content ul {
  font-size: 1rem;
  color: #666;
}

.home-additional-content ul {
  list-style-type: none;
  padding-left: 0;
}

.home-additional-content ul li {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .home-home-page {
    grid-template-columns: 1fr;
  }

  .home-h2 {
    font-size: 1.5rem;
  }

  .home-welcome-title {
    font-size: 2rem;
  }

  .home-welcome-message {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home-h2 {
    font-size: 1.3rem;
  }

  .home-welcome-title {
    font-size: 1.8rem;
  }

  .home-welcome-message {
    font-size: 0.9rem;
  }
}
