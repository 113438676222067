/* Import the 'Jua' font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

/* General styling for the entire page */
body {
  font-family: 'Jua', sans-serif;
  background-color: rgba(168, 237, 215, 0.2);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Center the content horizontally but align it at the start (top) vertically */
.register-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align at the top of the container */
}

.register-min-h-screen {
  min-height: 100vh;
}

.register-p-4 {
  padding: 16px;
}

/* Styling for the logo image */
.register-custom-image {
  width: 100%; /* Adjust for mobile screens */
  height: auto;
  max-width: 550px;
  margin-top: 20px;
  margin-bottom: 24px;
}

/* Styling for the main container */
.register-bg-white {
  background-color: #fff;
  padding: 32px; /* Ensure consistent padding */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
  width: 100%;
  max-width: 400px; /* Max width for responsiveness */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}

/* Styling for the header text */
h1 {
  margin: 0; /* Remove default margin */
  text-align: center; /* Center text */
  color: #2d3748; /* Gray color */
  font-size: 2.5rem; /* Large font size */
  font-weight: 700; /* Bold font */
  width: 100%; /* Ensure it takes full width */
}

/* Styling for input fields */
.register-input-box {
  width: 100%;
  height: 48px;
  padding: 12px;
  border-radius: 6px;
  font-size: 1.25rem;
  border: 1px solid #ddd;
  text-align: center;
  margin-bottom: 16px;
  box-sizing: border-box;
}

/* Smaller submit button */
.register-submit-button {
  width: 50%;    /* Full width for smaller screens */
  height: 40px;   /* Keep height fixed */
  font-size: 1rem;
  background-color: #38a169;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.register-submit-button:hover {
  background-color: #2f855a;
}

/* Styling for links */
.register-text-blue-500 {
  color: #4299e1;
}
.register-text-center{
  text-align: center;
}
.register-hover-underline:hover {
  text-decoration: underline;
}

.register-mt-4 {
  margin-top: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .register-text-4xl {
    font-size: 1.5rem;
  }

  .register-md-text-5xl {
    font-size: 2rem;
  }

  .register-input-box {
    font-size: 1rem;
  }

  .register-submit-button {
    font-size: 0.875rem;
  }
}
