.opportunity-opportunities {
  padding: 20px;
}

.opportunity-job-listings {
  margin-top: -200px;
  padding: 200px;
}

.create-opportunity-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
}

.opp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.opp-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.opp-modal-content input,
.opp-modal-content textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.opp-modal-content button {
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.opportunity-opportunities {
  padding: 20px;
}

.opportunity-job-listings {
  margin-top: -200px;
  padding: 200px;
}

.create-opportunity-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 20px auto;
  display: block;
}

.opp-modal-content {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  /* Make the modal responsive */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
  /* Allow scrolling if content is too large */
}

.opp-modal-content input,
.opp-modal-content textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.opp-modal-content button {
  background-color: transparent;
  color: #007bff;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.opp-cancel-button {
  background-color: transparent;
}

.opp-modal-content button:hover {
  background-color: #45a049;
}

.opp-create-button {
  background-color: #007bff;
}

@media (max-width: 768px) {
  .opportunity-job-listings {
    padding: 20px;
    margin-top: 0;
  }

  .create-opportunity-button {
    width: 50%;
  }

  .opp-modal-content {
    width: 90%;
    padding: 15px;
  }
}

.opp-modal-content button:hover {
  background-color: #45a049;
}

.opp-create-button {
  background-color: #007bff;
}

@media (max-width: 768px) {
  .opportunity-job-listings {
    padding: 20px;
    margin-top: 0;
  }

  .create-opportunity-button {
    width: 50%;
  }

  .opp-modal-content {
    width: 90%;
    padding: 15px;
  }
}