/* Import the 'Jua' font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');

/* General styling for the entire page */
body {
  font-family: 'Jua', sans-serif;
  background-color: rgba(168, 237, 215, 0.2);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Center the content horizontally but align it at the start (top) vertically */
.forget-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align at the top of the container */
}

.forget-min-h-screen {
  min-height: 100vh;
}

.forget-p-4 {
  padding: 16px;
}

/* Styling for the logo image */
.forget-custom-image {
  width: 100%;
  height: auto;
  max-width: 550px;
  margin-top: 20px;
  margin-bottom: 24px;
}

/* Styling for the main container */
.forget-bg-white {
  background-color: #fff;
}

.forget-p-8 {
  padding: 32px;
}

.forget-rounded-lg {
  border-radius: 10px;
}

.forget-shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.forget-w-full {
  width: 100%;
}

.forget-max-w-md {
  max-width: 400px;
  width: 90%; /* Adjust for mobile responsiveness */
}

/* Styling for the header text */
.forget-text-center {
  text-align: center;
}

.forget-text-gray-800 {
  color: #2d3748;
}

.forget-text-4xl {
  font-size: 1.75rem;
}
.forget-text-3xl {
  font-size: 1.55rem;
}

.forget-md-text-5xl {
  font-size: 2.5rem;
}

.forget-font-bold {
  font-weight: 700;
}



/* Styling for input fields */
.forget-input-box {
  width: 100%;
  height: 48px;
  padding: 12px;
  border-radius: 6px;
  font-size: 1.25rem;
  border: 1px solid #ddd;
  text-align: center;
  margin-bottom: 16px;
  box-sizing: border-box;
}

/* Smaller submit button */
.forget-submit-button {
  width: 50%;    /* Full width for smaller screens */
  height: 40px;   /* Keep height fixed */
  font-size: 1rem;
  background-color: #38a169;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.forget-submit-button:hover {
  background-color: #2f855a;
}

/* Styling for links */
.forget-text-blue-500 {
  color: #4299e1;
}

.forget-hover-underline:hover {
  text-decoration: underline;
}

.forget-mt-4 {
  margin-top: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .forget-text-4xl {
    font-size: 1.5rem;
  }

  .forget-md-text-5xl {
    font-size: 2rem;
  }

  .forget-input-box {
    font-size: 1rem;
  }

  .forget-submit-button {
    font-size: 0.875rem;
  }
}
