.admin_header-header {
    background-color: #eaf9f0;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .admin_header-header__logo img {
    height: 50px;
    width: auto;
    object-fit: contain;
  }
  
  .admin_header-header__nav ul {
    list-style-type: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
  }
  
  .admin_header-header__nav a {
    text-decoration: none;
    color: black;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
  
  }
  .admin_notification-badge {
    background-color: #ff5252; /* Red background for the badge */
    color: white; /* White text color */
    border-radius: 50%; /* Circular badge */
    padding: 3px 6px; /* Padding for the badge */
    margin-left: 5px; /* Space between text and badge */
    font-size: 12px; /* Font size of the badge */
    position: relative; /* To position it properly */
    top: -2px; /* Adjust the position if necessary */
  }
  
  .admin_header-header__nav a:hover {
    background-color: #d0f0e0;
  }
  
  .admin_header-header__nav .active {
    color: #1515e7;
    font-weight: 600;
    background-color: #d0f0e0;
  }
  
  /* Dropdown styles */
  .admin_header-dropdown {
    position: relative;
  }
  
  .admin_header-dropdown > a::after {
    content: " ▼"; /* Downward arrow */
    font-size: 0.8em;
    margin-left: 5px;
  }
  
  .admin_header-dropdown:hover > a::after {
    content: " ▲"; /* Upward arrow when dropdown is active */
  }
  
  .admin_header-dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 5px;
    right: 0;
  }
  
  .admin_header-dropdown:hover .admin_header-dropdown-content {
    display: block;
  }
  
  .admin_header-dropdown-content a {
    display: block;
    color: black;
    padding: 10px 15px;
    text-decoration: none;
  }
  
  .admin_header-dropdown-content a:hover {
    background-color: #d0f0e0;
  }
  
  /* Nested dropdown styles */
  .admin_header-nested-dropdown {
    position: relative;
  }
  
  .admin_header-nested-dropdown > a::after {
    content: " ▶"; /* Right arrow */
    font-size: 0.8em;
    margin-left: 5px;
  }
  
  .admin_header-nested-dropdown:hover > a::after {
    content: " ▼"; /* Downward arrow when nested dropdown is active */
  }
  
  .admin_header-nested-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    top: 0;
    left: 100%;
    margin-left: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
    border-radius: 5px;
  }
  
  .admin_header-nested-dropdown:hover .admin_header-nested-content {
    display: block;
  }
  
  /* Handle dropdown overflow for smaller screens */
  @media (max-width: 768px) {
    .admin_header-dropdown-content {
      left: 0;
      right: auto;
    }
  
    .admin_header-nested-content {
      left: auto;
      right: 0;
    }
  }
  