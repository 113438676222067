.group-chat {
    padding: 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Back Button Styling */
.back-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #0056b3;
}

/* Chat Messages Section */
.chat-messages {
    max-height: 400px;
    overflow-y: scroll;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message {
    padding: 12px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: transform 0.2s;
    width: 70%;
    display: flex;
    flex-direction: column; /* Stack sender name above the message */
}

.sender-name {
    font-weight: bold;
    font-size: 12px; /* Smaller size for username */
    color: #555; /* Lighter color to make it less prominent */
    margin-bottom: 5px; /* Space between the username and message */
}


.message-you {
    background-color: #007bff;
    color: white;
    margin-left: auto;
    width: 40%;
}

.message-other {
    background-color: #e5e5ea;
    color: black;
    margin-right: auto;
    width: 40%;
}


/* Chat Input Section */
.chat-input {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.chat-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
}

.chat-input button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.chat-input button:hover {
    background-color: #0056b3;
}

/* Tab Buttons */
.tab-buttons {
    display: flex;
    justify-content: space-around;
    margin: 10px 0;
}

.tab-buttons button {
    padding: 10px 20px;
    border: none;
    background-color: lightgray;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.tab-buttons .active {
    background-color: #007bff;
    color: white;
}

/* Leave Group Button */
.leave-group-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.leave-group-button:hover {
    background-color: darkred;
}

/* Posts Section */
.posts-section {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.post-item {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; /* Stack items vertically */
}

.post-username {
    font-weight: bold;
    color: #007bff; /* Optional: Username color */
    margin-bottom: 8px; /* Space between username and post details */
}

.post-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.post-details {
    flex-grow: 1;
}

.read-more {
    color: #007bff;
    text-decoration: none;
    padding: 8px 12px;
    border: 1px solid #007bff;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.read-more:hover {
    background-color: #007bff;
    color: white;
}

/* New Post Form */
.new-post-form {
    margin-top: 20px;
}

.new-post-form input,
.new-post-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.new-post-form button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.new-post-form button:hover {
    background-color: #0056b3;
}
.comment-username {
    font-weight: bold;
    font-size: 12px; /* Small size for the username */
    color: #555; /* Light color for the username */
    margin-right: 5px; /* Space between username and comment text */
}
.members-list {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between member cards */
}
.group-text-center{
    text-align: center;
}
.comment {
    margin-bottom: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: column; /* Stack the username and comment text */
}

.comments-section {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    width: 100%; /* Ensure it takes 100% of the parent width */
}

.new-comment {
    display: flex;
    gap: 10px;
    width: 100%; /* Full width for the comment input section */
}

.new-comment input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%; /* Ensure input takes full width */
}

.new-comment button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100px; /* Set a fixed width for the button */
}

.new-comment button:hover {
    background-color: #0056b3;
}


/* Scrollbar Styling for Chat Messages */
.chat-messages::-webkit-scrollbar {
    width: 8px;
}

.chat-messages::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.chat-messages::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}
.delete-group-button {
    background-color: darkorange;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    margin-left: 10px; /* Add some space between the buttons */
}

.delete-group-button:hover {
    background-color: orangered;
}
.group-delete-post-button {
    border: none;
    cursor: pointer;
    padding: 5px;
    background: none;
    margin-left: auto;
  }
  
  .group-delete-post-button:hover {
    opacity: 0.7;
    background-color: white;
  }