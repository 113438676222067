.admin-requests-container {
    padding: 20px;
  }
  
  .accordion-card {
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
  }
  
  .accordion-header {
    padding: 20px;
    background-color: #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
  }
  
  .accordion-header h3 {
    margin: 0;
  }
  
  .accordion-header p {
    margin: 0;
  }
  
  .accordion-content {
    padding: 20px;
  }
  
  .reply-section textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .reply-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reply-btn:hover {
    background-color: #0056b3;
  }
  
  .delete-btn {
    padding: 10px 20px;
    background-color: red;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .delete-btn:hover {
    background-color: darkred;
  }
  