.forum-page {
  padding: 20px;
  background-color: #f9f9f9;
}

h2 {
  font-size: 28px;
  color: #333;
}

p {
  font-size: 16px;
  color: #666;
}

h3 {
  margin-top: 20px;
  font-size: 22px;
  color: #333;
}

.posts-list {
  margin-top: 20px;
}

.post-box {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.post-box h4 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.post-box p {
  font-size: 16px;
  margin: 10px 0;
  color: #555;
}

.post-box small {
  display: block;
  font-size: 12px;
  color: #999;
}

.new-post-form {
  margin-top: 30px;
}

.new-post-form textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
}

.new-post-form button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.new-post-form button:hover {
  background-color: #45a049;
}

.posts-section {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.post-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.post-username {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 8px;
}

.post-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.post-details {
  flex-grow: 1;
}

.read-more {
  color: #007bff;
  text-decoration: none;
  padding: 8px 12px;
  border: 1px solid #007bff;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.read-more:hover {
  background-color: #007bff;
  color: white;
}

/* New Post Form */
.new-post-form {
  margin-top: 20px;
}

.new-post-form input,
.new-post-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.new-post-form button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new-post-form button:hover {
  background-color: #0056b3;
}

.comment-username {
  font-weight: bold;
  font-size: 12px;
  color: #555;
  margin-right: 5px;
}

.members-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group-text-center {
  text-align: center;
}

.comment {
  margin-bottom: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}

.comments-section {
  margin-top: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  width: 100%;
}

.new-comment {
  display: flex;
  gap: 10px;
  width: 100%;
}

.new-comment input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
}

.new-comment button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100px;
}

.new-comment button:hover {
  background-color: #0056b3;
}

.delete-post-button {
  border: none;
  cursor: pointer;
  padding: 5px;
  background: none;
  margin-left: auto;
}

.delete-post-button:hover {
  opacity: 0.7;
  background-color: white;
}


/* Tablet Screens (min-width: 600px) */
@media only screen and (min-width: 600px) {
  .forum-page {
    padding: 20px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 20px;
  }

  .post-box {
    padding: 12px;
  }

  .post-box h4 {
    font-size: 20px;
  }

  .posts-section {
    padding: 25px;
  }

  .new-post-form textarea {
    height: 120px;
  }

  .new-post-form button {
    padding: 10px 30px;
  }

  .post-content {
    flex-direction: row;
  }

  .new-comment {
    flex-direction: row;
  }

  .new-comment button {
    width: 120px;
  }
}

/* Laptop & Desktop Screens (min-width: 1024px) */
@media only screen and (min-width: 1024px) {
  .forum-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 22px;
  }

  .post-box {
    padding: 20px;
    margin-bottom: 20px;
  }

  .post-box h4 {
    font-size: 22px;
  }

  .new-post-form textarea {
    height: 150px;
  }

  .new-post-form button {
    padding: 10px 40px;
  }

  .new-comment button {
    width: 150px;
  }

  .new-comment {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .posts-section {
    padding: 30px;
  }

  .delete-post-button {
    padding: 8px;
  }
}

/* Large Desktop Screens (min-width: 1440px) */
@media only screen and (min-width: 1440px) {
  .forum-page {
    max-width: 1400px;
    padding: 40px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    font-size: 24px;
  }

  .post-box {
    padding: 25px;
  }

  .post-box h4 {
    font-size: 24px;
  }

  .new-post-form textarea {
    height: 180px;
  }

  .new-comment button {
    width: 200px;
  }
}