.articles-articles-page {
  padding: 20px;
  text-align: center;
}

.articles-create-article-button {
  background-color: #6ae485;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 100;
  width: calc(100% - 40px);
  max-width: 300px;
  margin: 10px auto;
}

.articles-create-article-button:hover {
  background-color: #23e166;
}

.articles-articles-list {
  margin-top: 20px;
}

.articles-article-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 20px auto;
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
}

.articles-article-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  border: 1px solid black;
}

.articles-article-details-left {
  text-align: left;
  width: 60%;
}

.articles-article-details-right {
  text-align: right;
  width: 30%;
}

.articles-read-more {
  text-align: center;
  margin-top: 10px;
  width: 25%;
}

.articles-read-more a {
  color: blue;
  text-decoration: none;
  display: inline-block;
}

.articles-read-more a:hover {
  text-decoration: underline;
}

.articles-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.articles-form-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.articles-article-form {
  display: flex;
  flex-direction: column;
}

.articles-article-form label {
  width: 100%;
  margin-bottom: 5px;
  text-align: left;
}

.articles-article-form input,
.articles-article-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.articles-article-form textarea {
  resize: vertical;
  height: 80px;
}

.articles-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.articles-submit-button,
.articles-close-button {
  width: 48%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.articles-submit-button {
  background-color: #4caf50;
  color: white;
}

.articles-submit-button:hover {
  background-color: #096c0b;
}

.articles-close-button {
  background-color: #e74c3c;
  color: white;
}

.articles-close-button:hover {
  background-color: #c23329;
}

.articles-description {
  color: #708090;
}

.articles-delete-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  display: flex; 
}

.articles-delete-button:hover {
  opacity: 0.7;
  background-color: rgb(30, 155, 213);
}

@media (max-width: 768px) {
  .articles-article-card {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  }

  .articles-article-details-left,
  .articles-article-details-right {
    width: 100%;
    text-align: left;
  }

  .articles-create-article-button {
    padding: 12px 15px;
    font-size: 18px;
  }

  .articles-form-popup {
    width: 90%;
  }

  .articles-article-form input,
  .articles-article-form textarea {
    font-size: 16px;
  }

  .articles-button-container {
    flex-direction: column;
  }

  .articles-submit-button,
  .articles-close-button {
    width: 100%;
    margin: 5px 0;
  }

  .articles-delete-button {
    margin-left: 5px;
  }
}

@media (max-width: 480px) {
  .articles-create-article-button {
    font-size: 16px;
  }

  .articles-delete-button {
    margin-left: 5px;
  }
}
