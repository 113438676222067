.manage-users {
    padding: 20px;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th,
  .user-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .user-table td {
    vertical-align: middle; /* Align content vertically */
  }
  
  /* Flexbox for the action buttons */
  .actions-cell {
    display: flex;
    gap: 5px; /* Reduce gap between buttons */
    justify-content: flex-start; /* Align buttons to the left */
  }
  
  /* Button styles */
  button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  button.edit-button {
    background-color: #4CAF50; /* Green for edit */
    color: white;
  }
  
  button.save-button {
    background-color: #007bff; /* Blue for save */
    color: white;
  }
  
  button.delete-button {
    background-color: #f44336; /* Red for delete */
    color: white;
  }
  
  button.delete-button:hover {
    background-color: #d32f2f; /* Darker red on hover */
  }
  .reset-button {
    background-color: #ff9800; /* Orange color for reset */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .reset-button:hover {
    background-color: #e68900; /* Darker orange on hover */
  }
    