.jobListing-job-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 2px solid #000;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #f0fdf4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.2s ease;
}

.jobListing-button-container {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.jobListing-interested-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.jobListing-interested-button:hover {
  background-color: #4caf50;
  color: white;;
}

.jobListing-delete-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ff0000;
  font-size: 18px;
}

.jobListing-job-card__info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.jobListing-job-card__title {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
  color: #000;
}

.jobListing-job-card__company {
  font-size: 16px;
  color: #5a00e5;
  text-decoration: none;
  margin-top: 5px;
}

.jobListing-job-card__description {
  font-size: 14px;
  color: #333;
  margin-top: 8px;
}

.jobListing-job-card__location {
  font-size: 14px;
  color: #000;
  margin: -6px 0;
}

@media (max-width: 768px) {
  .jobListing-job-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .jobListing-button-container {
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 10px;
  }
}
