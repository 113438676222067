.create-forum-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-forum-btn:hover {
  background-color: #0056b3;
}

.create-forum-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
  margin-top: 10px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 15px;
}

.create-forum-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-forum-form button {
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-forum-form button:hover {
  background-color: #218838;
}


.forums-list {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.forums-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
}

.forum-box {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.forum-box h3 {
  margin: 0;
  font-size: 20px;
  color: #2b2b2b;
}

.forum-box p {
  margin: 10px 0;
  font-size: 16px;
  color: #666;
}

.forum-box .button-group {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

.go-to-forum-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.go-to-forum-btn:hover {
  background-color: #45a049;
}

.delete-forum-btn {
  background-color:tomato;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.delete-forum-btn:hover {
  background-color: #c82333;
}

/* Responsive design */
@media (max-width: 1024px) {
  .forums-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .forums-grid {
    grid-template-columns: 1fr;
  }
}
