.requests-container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #e5e2e2;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .requests-heading {
    text-align: center;
    font-family: 'Jua', sans-serif;
    color: #333;
    margin-bottom: 20px;
  }
  
  .requests-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .request-card {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .request-card p {
    margin: 10px 0;
    font-size: 16px;
    color: #333;
  }
  
  .request-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .accept-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .accept-button:hover {
    background-color: #45a049;
  }
  
  .delete-button {
    background-color: #f44336;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #e53935;
  }
  