.searchBar-search-bar {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.searchBar-search-bar input {
  width: 60%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.searchBar-search-button {
  margin-left: 10px;
  padding: 10px;
  font-size: 18px;
  background-color: #ddd;
  border: none;
  cursor: pointer;
}
