/* MemberCard.css */
.member-card {
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    margin: 10px 0;
    transition: background-color 0.3s;
}

.member-card:hover {
    background-color: #f0f0f0;
}

.member-avatar {
    width: 40px;
    height: 40px;
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-right: 10px;
}

.member-name {
    font-weight: bold;
    font-size: 16px;
}
